import * as React from "react";
import { ResponsiveAppBar } from "./ResponsiveAppBar";
import Box from "@mui/material/Box";
import { Footer } from "./Footer";
import { useHomepageBySlug } from "./queries";
import { Hero, SecondaryImages } from "./Hero";
import { useDocumentTitle } from "usehooks-ts";
import { Testimonials } from "./Testimonials";
import { CentredLoading } from "./CentredLoading";

export const MarketingSite = () => {
  const { data: entry } = useHomepageBySlug("designer-home");

  useDocumentTitle("Pitch2Table - Pitch Support for Designers");

  return (
    <Box>
      <ResponsiveAppBar />
      <Hero />
      {entry ? <SecondaryImages features={entry.fields.featuresV2 || []} /> : <CentredLoading />}
      {false && <Testimonials />}
      <Footer />
    </Box>
  );
};
