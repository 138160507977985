import React, { createContext, useEffect, useMemo, useState } from "react";
import { fetchConfig } from "./config-service.js";

/**
 * @type {ServerConfig}
 */
const initialState = { version: "not set", sha: "not set" };

const ConfigContext = createContext(initialState);

const ConfigProvider = ({ children }) => {
  /**
   * @type {[ServerConfig,React.Dispatch<React.SetStateAction<ServerConfig>>]}
   */
  const [config, setConfig] = useState(initialState);

  useEffect(() => {
    async function fetchData() {
      const config = await fetchConfig();
      setConfig(config);
    }

    fetchData();
  }, []);

  const value = useMemo(
    () => ({
      ...config,
    }),
    [config]
  );

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};

export default ConfigProvider;
