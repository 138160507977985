import React from "react";
import Typography from "@mui/material/Typography";

export const Heading = ({ children, ...props }) => (
  <Typography
    style={{
      fontFamily: "proxima-nova, sans-serif",
      fontWeight: 500,
      fontStyle: "normal",
    }}
    {...props}
  >
    {children}
  </Typography>
);
