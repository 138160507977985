import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import React from "react";
import { ResponsiveAppBar } from "./core/pages/ResponsiveAppBar";
import { MaxWidthBox } from "./core/pages/MaxWidthBox";
import { Highlight } from "./core/pages/Highlight";
import { useFeatureList } from "./core/pages/queries";
import { useDocumentTitle } from "usehooks-ts";
import { Feature } from "./Feature";
import { Footer } from "./core/pages/Footer";

export const FeaturesOverview = () => {
  const { data: features = [] } = useFeatureList("Features Overview");

  useDocumentTitle("Pitch2Table - Features");

  return (
    <Box>
      <ResponsiveAppBar />
      <Box pt={4} pb={0} display="flex" justifyContent="center" alignItems="center">
        <MaxWidthBox>
          <Typography variant="h3" p={2} textAlign="center" fontWeight="bold" style={{ lineHeight: 1.1 }}>
            All the tools you need to take your game from <Highlight>pitch</Highlight> to the{" "}
            <Highlight>table</Highlight>.
          </Typography>
          <Typography p={2} textAlign="center" fontSize={{ md: "1.6rem" }}>
            Pitch2Table is a platform built for game designers containing familiar tools – all tailored to what we do,
            all built to help us focus on what we do best. Take a look at what we have and see how it can help you.
          </Typography>
          <Typography p={2} textAlign="center" fontSize={{ md: "1.6rem" }}>
            New features every week.
          </Typography>
          <Box pt={2}>
            <Grid container spacing={2}>
              {features.map((feature) => (
                <Feature key={feature.title} feature={feature} />
              ))}
            </Grid>
          </Box>
        </MaxWidthBox>
      </Box>
      <Footer />
    </Box>
  );
};
