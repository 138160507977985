import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

export const Feature = ({ feature }) => (
  <Grid item size={6}>
    <Box>
      <Card elevation={3}>
        <Link
          component={RouterLink}
          className="brand"
          align="center"
          variant="h6"
          color="secondary"
          to={`/features/${feature.slug}`}
        >
          <CardMedia
            className="feature"
            component="img"
            sx={{ objectFit: "contain" }}
            image={feature.image}
            title={feature.title}
          />
        </Link>
      </Card>
      <CardContent>
        <Box display="flex" flexDirection="column">
          <Link
            component={RouterLink}
            className="brand"
            align="center"
            underscore="always"
            variant="h6"
            color="secondary"
            to={`/features/${feature.slug}`}
          >
            {feature.title}
          </Link>
          <Typography align="center" variant="body2" style={{ fontSize: "1.rem" }}>
            {feature.description}
          </Typography>
        </Box>
      </CardContent>
    </Box>
  </Grid>
);

export const FeatureHomePage = ({ feature }) => (
  <Box p={2}>
    <Card elevation={3}>
      <Grid container spacing={2}>
        <Grid item size={6}>
          <CardContent>
            <Box display="flex" flexDirection="column">
              <Typography align="center" variant="h4" color="secondary">
                {feature.title}
              </Typography>
              <Typography align="center" variant="body1" style={{ fontSize: "1.rem" }}>
                {feature.description}
              </Typography>
            </Box>
          </CardContent>
        </Grid>
        <Grid item size={6}>
          <Link
            component={RouterLink}
            className="brand"
            align="center"
            variant="h6"
            color="secondary"
            to={`/features/${feature.slug}`}
          >
            <CardMedia
              className="feature"
              component="img"
              sx={{ objectFit: "contain" }}
              title={feature.title}
              image={feature.image}
            />
          </Link>
        </Grid>
      </Grid>
    </Card>
  </Box>
);
