import React from "react";
import Grid from "@mui/material/Grid";

export const CenterOnPage = ({ children }) => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    sx={{ minHeight: "100vh" }}
  >
    <Grid item xs={3}>
      {children}
    </Grid>
  </Grid>
);
