import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import reportWebVitals from "./reportWebVitals";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import ConfigProvider from "./config/ConfigProvider";
import { AppFrame } from "./AppFrame";
import { theme } from "./theme";
import { MarketingSite } from "./core/pages/MarketingSite";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto-condensed/400.css";
import "@fontsource/roboto-condensed/700.css";
import "./index.css";
import "./calendar/style.css";
import { ContentfulPage } from "./core/pages/ContentfulPage";
import { ErrorPage } from "./ErrorPage";
import { FeaturesOverview } from "./FeaturesOverview";
import { Updates } from "./Updates";
import { FeatureOverview } from "./FeatureOverview";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: <MarketingSite />,
  },
  {
    path: "/error",
    element: <ErrorPage />,
  },
  {
    path: "/new",
    element: <Updates />,
  },
  {
    path: "/features",
    element: <FeaturesOverview />,
  },
  {
    path: "/features/:slug",
    element: <FeatureOverview />,
  },
  {
    path: "/:slug",
    element: <ContentfulPage />,
  },
]);

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
          <ConfigProvider>
            <CssBaseline />
            <AppFrame>
              <RouterProvider router={router} />
            </AppFrame>
          </ConfigProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
