import * as React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const GetStartedButton = () => (
  <Stack flexDirection="column">
    <Button href="https://app.pitch2table.com" variant="contained" color="primary">
      GET STARTED
    </Button>
    <Typography pt={1} textAlign="center" color="primary">
      (free for designers)
    </Typography>
  </Stack>
);
