import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import React from "react";
import { ResponsiveAppBar } from "./core/pages/ResponsiveAppBar";
import { MaxWidthBox } from "./core/pages/MaxWidthBox";
import { Highlight } from "./core/pages/Highlight";
import updates from "./assets/updates.json";
import CampaignIcon from "@mui/icons-material/Campaign";
import BugReport from "@mui/icons-material/BugReport";

export const Updates = () => {
  return (
    <Box>
      <ResponsiveAppBar />
      <Box pt={4} pb={0} display="flex" justifyContent="center" alignItems="center">
        <MaxWidthBox>
          <Typography variant="h3" p={2} textAlign="center" fontWeight="bold" style={{ lineHeight: 1.1 }}>
            <Highlight>New</Highlight> features keep dropping and old ones get keep getting{" "}
            <Highlight>better</Highlight>. Here are some highlights.
          </Typography>
          <List>
            {updates.map((month) => (
              <Box key={month.date}>
                <ListItem>
                  <ListItemText primary={<Typography variant="h6">{month.date}</Typography>} />
                </ListItem>
                {month.entries.map((update, i) => (
                  <Box pb={2} key={i}>
                    <Card>
                      <CardHeader
                        avatar={<Chip size="medium" color="success" label="NEW" />}
                        title={<Typography variant="h5">{update.title}</Typography>}
                      />
                      <CardMedia
                        component="img"
                        alt={update.title}
                        image="https://assets.pitch2table.com/assets/seo-banner.png"
                      />
                      <CardContent>
                        <Typography align="center" variant="subtitle1">
                          <em>{update.quote}</em>
                        </Typography>
                        <Box pt={2}>
                          <Typography>{update.description}</Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                ))}
                {month.updates.map((update, i) => (
                  <ListItem key={i}>
                    <ListItemIcon>
                      <CampaignIcon />
                    </ListItemIcon>
                    <ListItemText primary={update} />
                  </ListItem>
                ))}
                {month.fixes.length > 0 && (
                  <>
                    <ListItem>
                      <ListItemIcon>
                        <BugReport />
                      </ListItemIcon>
                      <ListItemText primary="Bug Fixes" />
                    </ListItem>
                    <ListItem>
                      <Stack direction="column">
                        {month.fixes.map((update, i) => (
                          <ListItemText key={i} primary={update} />
                        ))}
                      </Stack>
                    </ListItem>
                  </>
                )}
              </Box>
            ))}
          </List>
        </MaxWidthBox>
      </Box>
    </Box>
  );
};
