import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { BackToFeaturesBar } from "./core/pages/ResponsiveAppBar";
import { MaxWidthBox } from "./core/pages/MaxWidthBox";
import { useFeature } from "./core/pages/queries";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import CircularProgress from "@mui/material/CircularProgress";
import { HeroImage } from "./core/pages/Hero";
import { Footer } from "./core/pages/Footer";

const testingCallout = [
  {
    text: "Add publishers to your shortlist. Then prepare your pitch.",
    right: "1%",
    top: "16%",
    rotate: "6deg",
    arrowRight: "6%",
    arrowTop: "10%",
    arrowRotate: "230deg",
    arrowScale: "1 -1",
  },
  {
    text: "Choose from over 100 publishers added by other users and vetted by the team.",
    left: "29%",
    top: "37%",
    rotate: "3deg",
    arrowLeft: "43.5%",
    arrowTop: "29%",
    arrowRotate: "220deg",
    arrowScale: "1 -1",
  },
  {
    text: "Add custom publishers here if the one you're pitching to is missing",
    right: "1%",
    top: "80%",
    rotate: "-4deg",
    arrowRight: "34%",
    arrowTop: "74%",
    arrowRotate: "300deg",
    arrowScale: "1 1",
  },
];

export const FeatureOverview = () => {
  const { slug } = useParams();
  const { data: feature } = useFeature("Features Overview", slug);

  useEffect(() => {
    if (!feature) {
      return;
    }

    document.title = `Pitch2Table - ${feature.title}`;
  }, [feature]);

  return (
    <Box>
      {feature && (
        <Helmet>
          <meta name="description" content={feature.description} />
        </Helmet>
      )}
      <BackToFeaturesBar />
      <Box pt={4} pb={0} display="flex" justifyContent="center" alignItems="center">
        <MaxWidthBox>
          <Typography
            variant="h3"
            p={2}
            textAlign="center"
            fontWeight="bold"
            style={{ lineHeight: 1.1 }}
            color="secondary"
          >
            {feature ? feature.title : <Skeleton />}
          </Typography>
          <Typography p={2} textAlign="center" fontSize={{ md: "1.6rem", xs: "1.0rem" }}>
            {feature ? feature.description : <Skeleton />}
          </Typography>
          {feature ? <HeroImage image={feature.image} callouts={feature.annotations || []} /> : <CircularProgress />}
          {/* {feature ? <HeroImage image={feature.image} callouts={testingCallout} /> : <CircularProgress />} */}
        </MaxWidthBox>
      </Box>
      <Footer />
    </Box>
  );
};
