import React from "react";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import Typography from "@mui/material/Typography";

/**
 * @type {import("@contentful/rich-text-react-renderer").Options}
 */
export const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (_node, children) => (
      <Typography as="h1" color="secondary">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_2]: (_node, children) => (
      <Typography as="h2" color="primary" fontWeight="bold">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_3]: (_node, children) => (
      <Typography as="h3" color="primary" fontWeight="bold">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_4]: (_node, children) => (
      <Typography as="h4" color="primary" fontWeight="bold">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_5]: (_node, children) => (
      <Typography as="h5" color="primary" fontWeight="bold">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_6]: (_node, children) => (
      <Typography as="h6" color="primary" fontWeight="bold">
        {children}
      </Typography>
    ),
    [BLOCKS.PARAGRAPH]: (_node, children) => (
      <p>
        <Typography>{children}</Typography>
      </p>
    ),
    [BLOCKS.QUOTE]: (_node, children) => <blockquote>{children}</blockquote>,
  },
  preserveWhitespace: true,
  renderText: (text) => text.replace("!", "?"),
};
