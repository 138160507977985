import { createClient } from "contentful";
import { useQuery } from "@tanstack/react-query";

const client = createClient({
  space: "52jcul6cg5jj",
  accessToken: "ZHdDI7ANfcQ5jfJZ1y2M5EkoPWLXeNO3U50ilPj-N30",
});

const OneMinute = 60 * 1000;
const OneHour = OneMinute * 60;
const OneDay = OneHour * 24;

/**
 * @typedef {object} LinkNameAndSlug
 * @property {string} name
 * @property {string} slug
 */

const useLinkGroups = () => {
  return useQuery({
    queryKey: ["links"],
    queryFn: async () => {
      const entries = await client.getEntries({
        content_type: "menuGroup",
      });

      return entries;
    },
    staleTime: OneDay,
  });
};

/**
 * @param {string} internalTitle
 */
const useLinkGroup = (internalTitle) => {
  const { data: linkGroups } = useLinkGroups();

  return useQuery({
    queryKey: ["links-by-internal-title", internalTitle],
    queryFn: async () => {
      if (!linkGroups) {
        return null;
      }

      const page = linkGroups.items.find((item) => item.fields["internalTitle"] === internalTitle);
      if (!page) {
        return null;
      }

      const entry = await client.getEntry(page.sys.id);
      return entry;
    },
    enabled: !!linkGroups,
    staleTime: OneDay,
  });
};

/**
 *
 * @param {string} internalTitle
 * @returns {LinkNameAndSlug[]}
 */
export const useLinks = (internalTitle) => {
  const { data: links } = useLinkGroup(internalTitle);

  if (!links) {
    return [];
  }

  if (!links.fields.featuredPages) {
    return [];
  }

  return links.fields.featuredPages.map((page) => ({
    name: page.fields.pageName,
    slug: page.fields.slug,
  }));
};

const usePages = () => {
  return useQuery({
    queryKey: ["pages"],
    queryFn: async () => {
      const entries = await client.getEntries({
        content_type: "page",
      });

      return entries;
    },
    staleTime: OneDay,
  });
};

const useContentType = (type) => {
  return useQuery({
    queryKey: [type],
    queryFn: async () => {
      const entries = await client.getEntries({
        content_type: type,
      });

      return entries;
    },
    staleTime: OneDay,
  });
};

export const usePageBySlug = (slug) => {
  const { data: pages } = usePages();

  return useQuery({
    queryKey: ["page-by-slug", slug],
    queryFn: async () => {
      if (!pages) {
        return null;
      }

      const page = pages.items.find((item) => item.fields["slug"] === slug);
      if (!page) {
        return null;
      }

      const entry = await client.getEntry(page.sys.id);
      return entry;
    },
    enabled: !!pages,
    staleTime: OneDay,
  });
};

export const useHomepageBySlug = (slug) => {
  const { data: pages } = useContentType("homepage");

  return useQuery({
    queryKey: ["homepage-by-slug", slug],
    queryFn: async () => {
      if (!pages) {
        return null;
      }

      const page = pages.items.find((item) => item.fields["slug"] === slug);
      if (!page) {
        return null;
      }

      const entry = await client.getEntry(page.sys.id);
      return entry;
    },
    enabled: !!pages,
    staleTime: OneDay,
  });
};

const useWebsites = () => {
  return useQuery({
    queryKey: ["websites"],
    queryFn: async () => {
      const entries = await client.getEntries({
        content_type: "copyrightNotice",
      });

      return entries;
    },
    staleTime: OneDay,
  });
};

export const useWebsite = (website) => {
  const { data: pages } = useWebsites();

  return useQuery({
    queryKey: ["info-by-website", website],
    queryFn: async () => {
      if (!pages) {
        return null;
      }

      const page = pages.items.find((item) => item.fields["website"] === website);
      if (!page) {
        return null;
      }

      const entry = await client.getEntry(page.sys.id);
      return entry;
    },
    enabled: !!pages,
    staleTime: OneDay,
  });
};

export const useFeatureList = (name) => {
  return useQuery({
    queryKey: ["feature-list", name],
    queryFn: async () => {
      const response = await client.getEntries({
        content_type: "p2tFeatureList",
      });

      const entry = response.items.find((item) => item.fields.name === name);
      if (!entry) {
        return [];
      }

      return entry.fields.features.map((feature) => ({
        title: feature.fields.title,
        description: feature.fields.description,
        slug: feature.fields.slug,
        image: `http:${feature.fields.image.fields.file.url}`,
        annotations: feature.fields.annotations,
      }));
    },
    staleTime: OneDay,
  });
};

export const reduceFeature = (feature) => ({
  title: feature.fields.title,
  description: feature.fields.description,
  slug: feature.fields.slug,
  image: `http:${feature.fields.image.fields.file.url}`,
  annotations: feature.fields.annotations,
});

export const useFeature = (name, slug) => {
  return useQuery({
    queryKey: ["feature-list", name, slug],
    queryFn: async () => {
      const response = await client.getEntries({
        content_type: "p2tFeatureList",
      });

      const entry = response.items.find((item) => item.fields.name === name);
      if (!entry) {
        return [];
      }

      const features = entry.fields.features.map(reduceFeature);

      return features.find((f) => f.slug === slug);
    },
    staleTime: OneDay,
  });
};
